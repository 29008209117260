<template>
    <client-page>
        
        <sub-visual
        sh="제품 및 솔루션"
        sc="에프원시큐리티는 다방면으로 파트너의 정보를 보호합니다"
        tabActive="F1 WSFINDER"
        icon="/images/sub/visual/sv3-icon.svg"
        bg="/images/sub/visual/sv3.png">
        </sub-visual>
        
        <section class="sub-section-sm sub-section-sm--first">
            <v-container>
                <div class="section-title mb-32 mb-lg-56">
                    <h3 class="sub-tit--lg text-center mb-30 mb-lg-44" data-aos="fade-up">
                        웹셸(Web Shell) 이란?
                    </h3>
                    <p class="sub-tit--sm text-center">
                        웹셸은 공격자가 원격에서 웹서버에 명령을 수행할 수 있도록
                        작성한 웹스크립트(asp, php, jsp, cgi) 파일을 의미합니다
                    </p>
                </div>
                <v-row >
                    <v-col cols="12" lg="6" class="mb-20 mb-lg-0">
                        <div class="img-cap-wrap">
                            <div class="w-100 radius-lg bg-grey pa-16 pt30 py-md-40 py-lg-80">
                                <v-img class="d-block mx-auto" max-width="420" src="/images/sub/products/ws/s1-img.png"></v-img>
                            </div>
                            <p class="img-cap font-size-12 font-size-lg-16 color-white font-weight-bold text-center d-flex align-center justify-center bg-gra">
                                인터넷환경
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <div class="pl-xl-40 pt-lg-54">
                            <p class="page-text-lg color-point mb-10 mb-lg-20 font-weight-bold">
                                웹페이지 약어인 ‘웹(Web)’ 과 서버에게 명령을 내려 실행하기
                                위한 인터페이스 역할을 하는 ‘셸(Shell)’의 합성어 입니다.
                            </p>
                            <p class="page-text-lg color-gray mb-10 mb-lg-20">
                                웹페이지에서 서버에게 명령어를 실행하기 위하여 만들어진
                                프로그램 으로 원격에서 웹서버에 명령을 수행할 수 있도록
                                작성한 웹스크립트(asp, php, jsp, cgi 등)의 형태를 가집니다.
                            </p>
                            <p class="page-text-lg color-gray mb-10 mb-lg-20">
                                웹서버의 다양한 취약점 <br>
                                (서버 취약점, 웹 어플리케이션 취약점 등)을 타깃으로
                                공격하여 웹서버에 웹셸을 업로드 한 후 일반적인 웹 브라우저
                                (80port)를 통하여 업로드한 웹셸을 실행하여 침투한 서버상의
                                정보 유출 및 변조, 악성코드 유포 등의 불법 행위를 행하는
                                형태가 많습니다.
                            </p>
                            <p class="page-text-lg color-gray">
                                APT(Advanced Persistent Threat) 공격의 수단으로 
                                활용 합니다.
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="sub-section-sm">
            <v-container>
                <h3 class="sub-tit--md text-center mb-26 mb-lg-42" data-aos="fade-up">
                    웹셸 관리시스템
                </h3>
                <div class="border radius-md">
                    <div class="my-30 w-100 px-16">
                        <v-img class="d-block mx-auto mb-6 mb-lg-10" max-width="529" src="/images/sub/products/ws/s2-img.png"></v-img>
                        <p class="page-text-lg line-height-1 font-weight-bold text-center">구현전략</p>
                    </div>
                    <v-row no-gutters>
                        <v-col cols="12" lg="4" class="border-top border-right border-color-light">
                            <div class="d-flex bg-point justify-center align-center py-14 border-bottom border-color-light">
                                <v-img class="d-block mr-6 mr-lg-10" max-width="30" src="/images/sub/products/ws/s2-icon1-1.png"></v-img>
                                <p class="page-text-sm font-weight-bold color-white text-center">
                                    웹셸 탐지 구현 방안
                                </p>
                            </div>
                            <div class="py-10 border-bottom border-color-light">
                                <v-img class="mx-auto d-block" max-width="48" src="/images/sub/products/ws/arrow-bottom.png"></v-img>
                            </div>
                            <div class="py-10 border-bottom border-color-light bg-grey">
                                <p class="page-text-sm font-weight-bold color-point text-center">
                                    웹셸 탐지 기술 적용
                                </p>
                            </div>
                            <div class="pa-16 px-md-40 py-md-34">
                                <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-10">
                                    - 패턴탐지 기술적용
                                </p>
                                <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-10">
                                    - 파일기반 탐지 기술적용
                                </p>
                                <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-10">
                                    - 유사도 탐지 기술적용
                                </p>
                                <p class="color-gray bar-txt page-text-sm">
                                    - 난독화 탐지 기술적용
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="4" class="border-top border-right border-color-light">
                            <div class="d-flex bg-point justify-center align-center py-14 border-bottom border-color-light">
                                <v-img class="d-block mr-6 mr-lg-10" max-width="30" src="/images/sub/products/ws/s2-icon1-2.png"></v-img>
                                <p class="page-text-sm font-weight-bold color-white text-center">
                                    관리자 S/W 구현 방안
                                </p>
                            </div>
                            <div class="py-10 border-bottom border-color-light">
                                <v-img class="mx-auto d-block" max-width="48" src="/images/sub/products/ws/arrow-bottom.png"></v-img>
                            </div>
                            <div class="py-10 border-bottom border-color-light bg-grey">
                                <p class="page-text-sm font-weight-bold color-point text-center">
                                    관리시스템 구축
                                </p>
                            </div>
                            <div class="pa-16 px-md-40 py-md-34">
                                <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-10">
                                    - 시각적 직관성 적용 최적 그래픽 환경 Dash-Board
                                </p>
                                <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-10">
                                    - 악성 스크립트, 악성 URL, 해시정보 등
                                    패턴 유형별 관리 체계
                                    탐지패턴, 행위결과, 변종웹셸 유형별 웹셸 관리
                                </p>
                                <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-10">
                                    - 사용자 정보 및 사용자 인증을 통한
                                    에이전트 관리
                                </p>
                                <p class="color-gray bar-txt page-text-sm">
                                    - 웹셸 탐지 현황, 패턴  통계 정보 제공
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="4" class="border-top border-color-light">
                            <div class="d-flex bg-point justify-center align-center py-14 border-bottom border-color-light">
                                <v-img class="d-block mr-6 mr-lg-10" max-width="30" src="/images/sub/products/ws/s2-icon1-3.png"></v-img>
                                <p class="page-text-sm font-weight-bold color-white text-center">
                                    분석환경 외 기타 부문

                                </p>
                            </div>
                            <div class="py-10 border-bottom border-color-light">
                                <v-img class="mx-auto d-block" max-width="48" src="/images/sub/products/ws/arrow-bottom.png"></v-img>
                            </div>
                            <div class="py-10 border-bottom border-color-light bg-grey">
                                <p class="page-text-sm font-weight-bold color-point text-center">
                                    에이전트 부가 기능 적용
                                </p>
                            </div>
                            <div class="pa-16 px-md-40 py-md-34">
                                <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-10">
                                    - 악성 URL 탐지 기능 적용
                                </p>
                                <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-10">
                                    - 실시간 탐지 기능 적용
                                </p>
                                <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-10">
                                    - 패턴 업데이트
                                </p>
                                <p class="color-gray bar-txt page-text-sm">
                                    - 파일 스캔 / 검역소 기능 구현
                                </p>
                            </div>
                            <div class="py-10 border-top border-bottom border-color-light bg-grey">
                                <p class="page-text-sm font-weight-bold color-point text-center">
                                    기타
                                </p>
                            </div>
                            <div class="pa-16 px-md-40 py-md-34">
                                <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-10">
                                    - 웹셸의 분석결과 분류 기능 제공
                                </p>
                                <p class="color-gray bar-txt page-text-sm">
                                    - 에이전트 및 패턴의 배포 기능 제공
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </section>
        <section class="sub-section-sm sub-section--last">
            <v-container>
                <h3 class="sub-tit--md text-center mb-26 mb-lg-42" data-aos="fade-up">
                    F1-WSFinder 주요기능 요약
                </h3>
                <div class="py-16 py-lg-42 border-bottom" style="border-top: 2px solid var(--v-primary-base)">
                    <v-row>
                        <v-col cols="12" lg="2" class="d-flex justify-center">
                            <div class="bg-grey radius-xl d-flex align-center justify-center" style="width: 100px; height: 100px;">
                                <v-img class="mx-auto d-block" max-width="48" src="/images/sub/products/ws/s3-icon1-1.png"></v-img>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="2" class="d-flex d-lg-block justify-center pt-0 pt-lg-18">
                            <h6 class="page-text-lg font-weight-bold text-center text-lg-left">
                                웹셸/악성URL <br class="d-none d-lg-block">
                                탐지
                            </h6>
                        </v-col>
                        <v-col cols="12" lg="8">
                            <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-8">
                                - 유사도 탐지, 난독화 탐지 등 탐지 기술의 적용으로 웹셸 분석 능력 고도화
                            </p>
                            <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-8">
                                - 웹 소스 내 발견된 웹셸에 대해 파일해시 비교방식을 채택하여 O(n) 성능을 발휘하는 알고리즘 적용
                            </p>
                            <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-8">
                                - 포함된 악성코드 삽입 URL 탐지
                            </p>
                            <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-8">
                                - 파일개수, 파일 크기에 제약 조건 없는 실시간 탐지, 예외 처리 기능 포함
                            </p>
                            <p class="color-gray bar-txt page-text-sm">
                                - 주기탐지, 예약탐지, 실시간 탐지, 확장자 지정 탐지, 특정 폴더 예외처리 탐지 기능
                            </p>
                        </v-col>
                    </v-row>
                </div>
                <div class="py-16 py-lg-42 border-bottom">
                    <v-row>
                        <v-col cols="12" lg="2" class="d-flex justify-center">
                            <div class="bg-grey radius-xl d-flex align-center justify-center" style="width: 100px; height: 100px;">
                                <v-img class="mx-auto d-block" max-width="48" src="/images/sub/products/ws/s3-icon1-2.png"></v-img>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="2" class="d-flex d-lg-block justify-center pt-0 pt-lg-18">
                            <h6 class="page-text-lg font-weight-bold text-center text-lg-left">
                                원격 관리
                            </h6>
                        </v-col>
                        <v-col cols="12" lg="8">
                            <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-8">
                                - 원격 탐지 점검 내역 조회를 통한 원격 기술지원
                            </p>
                            <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-8">
                                - 탐지 파일에 대한 격리, 복원, 예외, 확인(승인) 및 이력 관리
                            </p>
                            <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-8">
                                - 사용자, 에이전트, 패턴, 웹쉡 세분화된 원격 관리 기능
                            </p>
                            <p class="color-gray bar-txt page-text-sm">
                                - 시스템 패턴 자동 업데이트 및 Agent 원격 제어
                            </p>
                        </v-col>
                    </v-row>
                </div>
                <div class="py-16 py-lg-42 border-bottom">
                    <v-row>
                        <v-col cols="12" lg="2" class="d-flex justify-center">
                            <div class="bg-grey radius-xl d-flex align-center justify-center" style="width: 100px; height: 100px;">
                                <v-img class="mx-auto d-block" max-width="48" src="/images/sub/products/ws/s3-icon1-3.png"></v-img>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="2" class="d-flex d-lg-block justify-center pt-0 pt-lg-18">
                            <h6 class="page-text-lg font-weight-bold text-center text-lg-left">
                                편리한 관리 UI
                            </h6>
                        </v-col>
                        <v-col cols="12" lg="8">
                            <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-8">
                                - GUI 기반의 사용자 UI 적용
                            </p>
                            <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-8">
                                - 통합 웹 UI (윈도우, 리눅스 등 Agent 유형에 상관없이 동일 사용자 인터페이스 지원)
                            </p>
                            <p class="color-gray bar-txt page-text-sm mb-4 mb-lg-8">
                                - 패턴/웹셸/사용자/에이전트 별도의 관리기능 부여
                            </p>
                            <p class="color-gray bar-txt page-text-sm">
                                - 대시보드 및 통계 보고서
                            </p>
                        </v-col>
                    </v-row>
                </div>                
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>

<style lang="scss" scoped>
.img-cap-wrap{
    position: relative;
}

.img-cap{
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px 0;
    width: 90px;
    height: 30px;
}

.s2-box{
    border-radius: 0 0 var(--radius-md) var(--radius-md);
    overflow: hidden;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .img-cap{
        width: 162px;
        height: 44px;
    }
}
@media (min-width:1200px){
}

    /* Title */
    .tit-txt-wrap{
        margin-bottom: 40px;
    }
    .tit-wrap{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }
    .tit-txt-wrap .tit-wrap{
        margin-bottom: 10px;
    }
    .tit{
        width: 100%;
        font-size: 2.6rem;
        font-weight: 500;
        line-height: 1.2;
        word-break: keep-all;
    }
    .tit.font-notoserif{
        line-height: 1.4;
    }
    .tit--xs{
        font-size: 1.8rem;
        font-weight: 600;
    }
    .tit--sm{
        font-size: 2rem;
    }
    .tit--lg{
        font-size: 3.2rem;
        line-height: 40px;
        font-weight: 700;
        margin-bottom: 38px;
    }
    .tit-txt{
        font-size: 1.4rem;
    }


    @media (min-width:576px){
    }
    @media (min-width:768px){
        .tit{
            font-size: 3rem;
        }
        .tit--xs{
            font-size: 1.8rem;
        }
        .tit--lg{
            font-size: 4rem;
            line-height: 50px;
        }
    }
    @media (min-width:1024px){
        .tit-txt-wrap{
            margin-bottom: 60px;
        }
        .tit-wrap{
            margin-bottom: 60px;
        }
        .tit-txt-wrap .tit-wrap{
            margin-bottom: 20px;
        }
        .tit{
            font-size: 3.6rem;
        }
        .tit--xs{
            font-size: 2rem;
        }
        .tit--sm{
            font-size: 2.4rem;
        }
        .tit--lg{
            font-size: 5.2rem;
            line-height: 66px;
            margin-bottom: 72px
        }
        .tit-txt{
            font-size: 1.6rem;
        }
    }
    @media (min-width:1200px){
    }
/* Page Text */
.page-text{
    font-size: 1.6rem;
    line-height: 24px;
}
.page-text-sm{
    font-size: 1.4rem;
    line-height: 20px;
}
.page-text-lg{
    font-size: 1.6rem;
    line-height: 24px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .page-text{
        font-size: 2rem;
        line-height: 30px;
    }
    .page-text-sm{
        font-size: 1.8rem;
        line-height: 28px;
    }
    .page-text-lg{
        font-size: 2.2rem;
        line-height: 30px;
    }
}
@media (min-width:1200px){
}
</style>